// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import { gradeReasonsArray } from "models/fibers.model";

import React from "react";
import { LuClipboardCheck } from "react-icons/lu";
// Assets

export default function Grade(props) {
  const { title, letterGrade, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");

  return (
    <Card bg={bg} {...rest} p="14px">
      <Flex align="center" direction={{ base: "column", md: "row" }}>
        <Text
          paddingRight="20px"
          color={
            letterGrade === "A"
              ? "green.500"
              : letterGrade === "A+"
              ? "green.500"
              : letterGrade === "F"
              ? "red.500"
              : letterGrade === "D"
              ? "red.500"
              : letterGrade === "C"
              ? "orange.500"
              : letterGrade === "B"
              ? "orange.500"
              : null
          }
          fontWeight="500"
          fontSize="6xl"
          mb="4px"
        >
          {letterGrade}
        </Text>
        <Box mt={{ base: "10px", md: "0" }}>
          <Text
            color={textColorPrimary}
            fontWeight="500"
            fontSize="md"
            mb="4px"
          >
            {title}
          </Text>
          <Text fontWeight="500" color={brandColor} fontSize="sm">
            Reason for Grade:
          </Text>

          {gradeReasonsArray.map((item, index) => {
            if (item.grade === letterGrade) {
              return (
                <Text
                  fontWeight="500"
                  color={textColorSecondary}
                  fontSize="sm"
                  me="4px"
                  key={index}
                >
                  {item.reason}
                </Text>
              );
            }
            return "";
          })}
        </Box>

        <Icon
          as={LuClipboardCheck}
          color="secondaryGray.500"
          h="18px"
          w="18px"
        />
      </Flex>
    </Card>
  );
}
