import { createContext, useContext, useState } from "react";

const StateContext = createContext();

export const StateProvider = ({ children }) => {
  const [value, setValue] = useState([]);
  const [grade, setGrade] = useState("");
  const [garment, setGarment] = useState("");
  const [gptOutput, setGPTOutput] = useState(null);
  const [fabricComposition, setFabricComposition] = useState("");

  const updateValue = (newValue) => {
    setValue(newValue);
  };
  const gradeFabric = (newValue) => {
    setGrade(newValue);
  };
  const garmentValue = (newValue) => {
    setGarment(newValue);
  };
  const updateFabrics = (newValue) => {
    setFabricComposition(newValue);
  };
  const gptOutputObj = (newValue) => {
    setGPTOutput(newValue);
  };

  return (
    <StateContext.Provider
      value={{
        value,
        grade,
        garment,
        fabricComposition,
        gptOutput,
        updateValue,
        gradeFabric,
        garmentValue,
        updateFabrics,
        gptOutputObj,
      }}
    >
      {children}
    </StateContext.Provider>
  );
};

export const useStateManager = () => {
  return useContext(StateContext);
};
