import React from "react";
import ReactDOM from "react-dom";
import "assets/css/App.css";
import { HashRouter, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/admin";
import { ChakraProvider } from "@chakra-ui/react";
import theme from "theme/theme";

import Analyzer from "views/admin/material_analyzer";

// let user = localStorage.getItem("user");
// user = JSON.parse(user);
ReactDOM.render(
  <ChakraProvider theme={theme}>
    {/* <AuthProvider userData={user}> */}
    <React.StrictMode>
      <HashRouter>
        <Switch>
          <Route path={`/auth`} component={AdminLayout} />
          <Route path={`/analyzer`} render={() => <Analyzer />} />
          {/* <Route path={`/admin`} component={AdminLayout} />
          <Route path={`/rtl`} component={RTLLayout} /> */}
          <Redirect from="/" to="/analyzer" />
        </Switch>
      </HashRouter>
    </React.StrictMode>
    {/* </AuthProvider> */}
  </ChakraProvider>,
  document.getElementById("root")
);
