// Chakra imports
import { Text, useColorModeValue } from "@chakra-ui/react";
// Assets
import Project1 from "assets/img/profile/Project1.png";

// Custom components
import Card from "components/card/Card.js";
import React from "react";
import Grade from "./Grade";
import { useStateManager } from "./stateManager";
import SustainabilityRating from "./SustainabilityRating";
import QualityRating from "./QualityRating";

export default function Results(props) {
  const { ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const { grade, gptOutput } = useStateManager();

  return (
    gptOutput && (
      <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px"
        >
          Results
        </Text>
        <Text color={textColorSecondary} fontSize="md" me="26px" mb="40px">
          Get the inside scoop on your clothes! This detailed garment breakdown
          reveals scores for sustainability, quality, and every fabric blend. We
          champion natural fibers in our rankings, guiding you towards
          eco-friendly and durable options.
        </Text>
        <Grade
          boxShadow={cardShadow}
          mb="20px"
          image={Project1}
          ranking="1"
          title="Fabric Blend Grade"
          letterGrade={grade}
        />
        <SustainabilityRating
          boxShadow={cardShadow}
          mb="20px"
          title="Sustainability Rating"
          rating={gptOutput?.overallSustainabilityRating}
          explanation={gptOutput?.overallSustainabilityRatingExplanation}
        />
        <QualityRating
          boxShadow={cardShadow}
          mb="20px"
          title="Quality Rating"
          rating={gptOutput?.overallQualityRating}
          explanation={gptOutput?.overallQualityRatingExplanation}
        />
      </Card>
    )
  );
}
