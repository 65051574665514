// Chakra imports
import { Box, Button, Flex, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import { useStateManager } from "./stateManager";
import { callChatGPT } from "../functions/GPT";

// Assets

import Inputzone from "views/admin/material_analyzer/components/Inputzone";
import {
  gradeBlendRatio,
  parseMaterial,
  validateMaterialPercentages,
} from "../functions/resultsFunctions";
import { useState } from "react";
export default function MaterialInput(props) {
  const { used, total, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const [clothing, setClothing] = useState("");
  const [fabrics, setFabric] = useState("");
  const [inputInvalid, setInputInvalid] = useState(false);

  const {
    updateValue,
    gradeFabric,
    garmentValue,
    updateFabrics,
    gptOutputObj,
  } = useStateManager();
  const [loading, setLoading] = useState(false);

  //const history = useHistory();

  const getValue = async (input) => {
    // Assuming these functions are asynchronous, make sure to wait for them to complete
    const array = await parseMaterial(input);
    const isValid = validateMaterialPercentages(array);
    if (isValid === true) {
      console.log();
      updateValue(array);
      setInputInvalid(false);
      const grade = await gradeBlendRatio(input);
      gradeFabric(grade);
      garmentValue(clothing);
      updateFabrics(fabrics);
      return false;
    } else {
      setInputInvalid(true);
      return true;
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const checkIfInalid = await getValue(fabrics);
    if (checkIfInalid === false) {
      const result = await callChatGPT(clothing, fabrics);
      gptOutputObj(JSON.parse(result));
    }

    setLoading(false);
  };

  return (
    <Card {...rest} mb="20px" align="center" p="20px" w="100%">
      <form>
        <Flex
          h="100%"
          w="100%"
          justify="space-between"
          mb="30px"
          direction={{ base: "column" }}
        >
          <Text color={textColorPrimary} textAlign="start" fontSize="lg">
            Type of Clothing
          </Text>
          <Inputzone
            me="36px"
            placeholder="ex. jeans"
            value={clothing}
            onChange={(e) => setClothing(e.target.value)}
            errorColor="green"
            invalid={true}
          />
          <Text color={textColorPrimary} textAlign="start" fontSize="lg">
            Material Composition
          </Text>

          <Inputzone
            me="36px"
            placeholder="ex. 53% Cotton, 47% Nylon"
            value={fabrics}
            onChange={(e) => setFabric(e.target.value)}
            invalid={inputInvalid}
            errorColor="red.300"
            errorText="Percentages must add up to 100% make sure to add commas between materials"
          />

          <Flex direction="column" pe="44px">
            <Text
              color={textColorPrimary}
              fontWeight="bold"
              textAlign="start"
              fontSize="2xl"
              mt={{ base: "20px", "2xl": "50px" }}
            >
              Generate Analysis
            </Text>
            <Text
              color={textColorSecondary}
              fontSize="md"
              my={{ base: "auto", "2xl": "10px" }}
              textAlign="start"
            >
              *Analysis assumes standard industry practices and does not
              consider specific brand or manufacturing details
            </Text>
            <Flex w="100%">
              <Button
                isLoading={loading}
                loadingText="Give me 10 Seconds"
                me="100%"
                minW="240px"
                mt={{ base: "20px", "2xl": "auto" }}
                variant="brand"
                fontWeight="500"
                id="submitButton"
              >
                Submit
              </Button>
            </Flex>
          </Flex>
        </Flex>
      </form>
    </Card>
  );
}
