// Chakra imports
import {
  Box,
  Flex,
  Icon,
  Link,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
// Assets
import { MdEdit } from "react-icons/md";
import { LuThumbsUp } from "react-icons/lu";
import { FiThumbsDown } from "react-icons/fi";

export default function QualityRating(props) {
  const { title, rating, explanation, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const brandColor = useColorModeValue("brand.500", "white");
  const bg = useColorModeValue("white", "navy.700");
  return (
    <Card bg={bg} {...rest} p="14px">
      <Flex align="center" direction={{ base: "column", md: "row" }}>
        <Text
          paddingRight="20px"
          color={rating >= 6 ? "green.500" : "red.500"}
          fontWeight="500"
          fontSize="6xl"
          mb="4px"
        >
          {rating}
        </Text>
        <Box mt={{ base: "10px", md: "0" }}>
          <Text
            color={textColorPrimary}
            fontWeight="500"
            fontSize="md"
            mb="4px"
          >
            {title}
          </Text>
          <Text fontWeight="500" color={brandColor} fontSize="sm">
            Reason for rating:
          </Text>
          <Text
            fontWeight="500"
            color={textColorSecondary}
            fontSize="sm"
            me="4px"
          >
            {explanation}
          </Text>
        </Box>

        {rating >= 7 ? (
          <Icon as={LuThumbsUp} color="secondaryGray.500" h="18px" w="18px" />
        ) : (
          <Icon as={FiThumbsDown} color="secondaryGray.500" h="18px" w="18px" />
        )}
      </Flex>
    </Card>
  );
}
