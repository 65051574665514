// Chakra imports
import {
  Box,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  Input,
  useColorModeValue,
} from "@chakra-ui/react";
// Assets
import React from "react";

function Inputzone(props) {
  const { content, placeholder, invalid, errorColor, errorText, ...rest } =
    props;

  const bg = useColorModeValue("gray.100", "navy.700");
  const borderColor = useColorModeValue("secondaryGray.100", "whiteAlpha.100");

  return (
    <Box>
      <FormControl isInvalid={invalid}>
        <Flex
          align="center"
          justify="center"
          bg={bg}
          border="1px dashed"
          borderColor={borderColor}
          borderRadius="16px"
          w="100%"
          cursor="pointer"
          marginBottom={"20px"}
          {...rest}
        >
          <Input
            errorBorderColor={errorColor}
            isRequired
            placeholder={placeholder}
            size="lg"
          />
        </Flex>
        {!invalid ? (
          <FormHelperText></FormHelperText>
        ) : (
          <FormErrorMessage>{errorText}</FormErrorMessage>
        )}
      </FormControl>
    </Box>
  );
}

export default Inputzone;
