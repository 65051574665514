export const naturalFibersList = [
  "Cotton",
  "Flax",
  "Linen",
  "Hemp",
  "Jute",
  "Ramie",
  "Abaca",
  "Sisal",
  "Coir",
  "Bamboo",
  "Wool",
  "Silk",
  "Alpaca",
  "Mohair",
  "Cashmere",
  "Angora",
  "Llama",
  "Yak",
  "Quiviut",
  "Recycled Cotton",
];
export const gradeReasonsArray = [
  {
    grade: "A+",
    reason:
      "100% natural fibers. Excellent sustainability and eco-friendliness.",
  },
  {
    grade: "A",
    reason:
      "At least 90% natural fibers. Excellent sustainability and eco-friendliness.",
  },
  {
    grade: "B",
    reason:
      "80-89% natural fibers. Good sustainability, but with room for improvement.",
  },
  {
    grade: "C",
    reason:
      "70-79% natural fibers. Average sustainability. Consider increasing natural fiber content.",
  },
  {
    grade: "D",
    reason:
      "60-69% natural fibers. Below-average sustainability. Significant improvement needed.",
  },
  {
    grade: "E",
    reason:
      "50-59% natural fibers. Poor sustainability. Strongly consider increasing natural fiber content.",
  },
  {
    grade: "F",
    reason:
      "Below 50% natural fibers. Very poor sustainability. Urgent need for a more eco-friendly composition.",
  },
];
export const gradeReasons = {
  "A+": {
    reason:
      "100% natural fibers. Excellent sustainability and eco-friendliness.",
  },
  A: {
    reason:
      "At least 90% natural fibers. Excellent sustainability and eco-friendliness.",
  },
  B: {
    reason:
      "80-89% natural fibers. Good sustainability, but with room for improvement.",
  },
  C: {
    reason:
      "70-79% natural fibers. Average sustainability. Consider increasing natural fiber content.",
  },
  D: {
    reason:
      "60-69% natural fibers. Below-average sustainability. Significant improvement needed.",
  },
  E: {
    reason:
      "50-59% natural fibers. Poor sustainability. Strongly consider increasing natural fiber content.",
  },
  F: {
    reason:
      "Below 50% natural fibers. Very poor sustainability. Urgent need for a more eco-friendly composition.",
  },
};

// Example usage:
const grade = "C";
console.log(gradeReasons[grade].reason); // Output: "70-79% natural fibers. Average sustainability. Consider increasing natural fiber content."

export const fiberInfo = {
  Cotton: {
    fabricName: "Cotton",
    benefits:
      "Comfort: Breathable and comfortable. Absorbency: High moisture-wicking properties.Hypoallergenic: Generally safe for sensitive skin.Versatility: Easily dyed for various styles",
    drawbacks:
      "Wrinkling: Prone to wrinkles, requiring more caree.Shrinkage: May shrink after washing.Durability: Less durable compared to synthetics. Fading: Colors may fade with washing and sunlight.",
    source: "https://www.cottoninc.com/quality-products/nonwovens/why-cotton/",
  },
  Linen: {
    fabricName: "Linen",
    benefits:
      "Excellent strength, gains strength when wet. Hydrophilic: absorbs water and dries quickly. Cool in warm weather. Washable. Withstands very high temperatures when washing and ironing. No static, pilling, or lint problems.",
    drawbacks:
      "Wrinkles very easily. Fair abrasion, low durability. Poor drape and elasticity.",
    bestClothes: "Suits, skirts, jackets, dresses.",
    source:
      "https://www.textileschool.com/amp/207/flax-linen-fibers-natural-bast-fibers/, https://oldworldlinen.com/pages/flax-benefits",
  },
  Flax: {
    fabricName: "Flax",
    benefits:
      "Excellent strength, gains strength when wet. Hydrophilic: absorbs water and dries quickly. Cool in warm weather. Washable. Withstands very high temperatures when washing and ironing. No static, pilling, or lint problems.",
    drawbacks:
      "Wrinkles very easily. Fair abrasion, low durability. Poor drape and elasticity.",
    bestClothes: "Suits, skirts, jackets, dresses.",
    source:
      "https://www.textileschool.com/amp/207/flax-linen-fibers-natural-bast-fibers/, https://oldworldlinen.com/pages/flax-benefits",
  },
  Hemp: {
    fabricName: "Hemp",
    benefits:
      "Durable and strong.Environmentally friendly cultivation with minimal pesticides. Antibacterial and resistant to mold. Breathable and moisture-wicking. UV resistant. Biodegradable. Softens with each wash.",
    drawbacks:
      "Wrinkles easily. Limited color options. Slightly coarse texture. Initial stiffness that improves with wear and washing.",
    bestClothes: "Casual wear, denim, activewear, accessories.",
    source: "https://www.textileexchange.org/materials/hemp/",
  },
  Jute: {
    fabricName: "Jute",
    benefits:
      "Biodegradable and eco-friendly. High tensile strength. Breathable and comfortable. Insulating properties. UV resistant. Versatile use in various products.",
    drawbacks:
      "Prone to wrinkles. Absorbs moisture easily. Limited elasticity. Coarse texture. Fades over time when exposed to sunlight.",
    bestProducts: "Bags, carpets, rugs, packaging materials, home decor.",
    LinkName: "Fiber2Fiber",
    source:
      "https://www.fibre2fashion.com/industry-article/5522/eco-friendly-textile-fiber-jute",
  },
  Abaca: {
    fabricName: "Abaca",
    benefits:
      "Durable and flexible. Resistant to saltwater damage. Biodegradable and sustainable. Excellent strength and toughness. Naturally glossy appearance.",
    drawbacks:
      "Limited elasticity. Prone to wrinkles. Limited color options. Special care needed in washing.",
    bestProducts:
      "Ropes, twines, paper products, handicrafts, specialty textiles.",
    source:
      "https://www.textilelearner.net/2019/02/abaca-fiber-properties-and-uses.html",
  },
};
