// Chakra imports
import { Box, Grid, Heading, Text, useColorModeValue } from "@chakra-ui/react";

// Custom components

import Results from "./components/Results";
import MaterialInput from "views/admin/material_analyzer/components/MaterialInput";
import "styles/main.css";

// Assets
import React from "react";
import Fabric from "./components/Fabric";
import { StateProvider } from "./components/stateManager";
import Footer from "components/footer/FooterAdmin";
import CareInstructions from "./components/CareInstructions";
import SubscriptionBox from "./components/Subscription";

export default function Analyzer() {
  const textColor = useColorModeValue("navy.700", "white");

  return (
    <StateProvider>
      <Box
        minHeight="100vh"
        height="100%"
        overflow="auto"
        maxHeight="100%"
        w={{ base: "100%", xl: "calc( 100% - 290px )" }}
        maxWidth={{ base: "100%", xl: "calc( 100% - 290px )" }}
        transition="all 0.33s cubic-bezier(0.685, 0.0473, 0.346, 1)"
        transitionDuration=".2s, .2s, .35s"
        transitionProperty="top, bottom, width"
        mx={"auto"}
        p="4"
        transitionTimingFunction="linear, linear, ease"
      >
        <Box pt={{ base: "130px", md: "80px", xl: "80px" }}>
          {/* Main Fields */}
          <Heading color={textColor} fontSize="36px" mb="10px">
            Fabric Content Analyzer
          </Heading>
          <Text color={"red"} fontSize="lg" me="26px" mb="40px">
            Thank you for your interest in this product it has been
            decomissioned.
          </Text>

          <Text fontSize="md" me="26px" mb="40px">
            Confused about "sustainable" and "quality" fashion? Fabric Content
            Analyzer cuts through the greenwashing with AI-powered fabric
            analysis. Our system rates clothing based on natural fiber content,
            prioritizing eco-friendly materials known for comfort and longevity.
            Say goodbye to hype and hello to informed sustainable fashion
            choices!
          </Text>
          <MaterialInput
            gridArea={{
              base: "3 / 1 / 4 / 2",
              lg: "2 / 1 / 3 / 3",
              "2xl": "1 / 3 / 2 / 4",
            }}
            minH={{ base: "auto", lg: "420px", "2xl": "365px" }}
            pe="20px"
            pb={{ base: "100px", lg: "20px" }}
          />
          <Grid
            mb="20px"
            templateColumns={{
              base: "1fr",
              lg: "repeat(2, 1fr)",
              "2xl": "1.34fr 1.62fr",
            }}
            templateRows={{
              base: "1fr",
              lg: "1fr", // Single row for lg configuration
              "2xl": "1fr 0.4fr 0.4fr", // Two rows for 2xl configuration
            }}
            gap={{ base: "20px", xl: "20px" }}
          >
            <Results
              gridArea={{
                base: "1 / 1 / 2 / -1", // Full width in base configuration
                lg: "1 / 1 / 2 / span 1", // 50% width in lg configuration
                "2xl": "1 / 1 / 2 / span 1", // 50% width in 2xl configuration
              }}
              minH="365px"
              pe="20px"
            />
            <Fabric
              gridArea={{
                base: "2 / 1 / 3 / -1", // Full width in base configuration
                lg: "1 / 2 / 2 / -1", // 50% width in lg configuration
                "2xl": "1 / 2 / 2 / -1", // 50% width in 2xl configuration
              }}
              minH="365px"
              pe="20px"
            />
            <CareInstructions
              used={25.6}
              total={50}
              gridArea={{
                base: "3 / 1 / 4 / -1", // Full width in base configuration
                lg: "3 / 1 / 4 / -1", // Full width in lg configuration
                "2xl": "2 / 1 / 3 / -1", // Full width in 2xl configuration
              }}
            />
            <SubscriptionBox
              used={25.6}
              total={50}
              width={"100%"} // Set the width to 100%
              height={"250px"}
              gridArea={{
                base: "4 / 1 / 5 / -1", // Full width in base configuration on a separate row
                lg: "4 / 1 / 5 / -1", // Full width in lg configuration on a separate row
                "2xl": "3 / 1 / 4 / 3", // Updated gridArea for SubscriptionBox in 2xl
              }}
            />
          </Grid>

          <Box>
            <Footer />
          </Box>
        </Box>
      </Box>
    </StateProvider>
  );
}
