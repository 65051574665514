import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import { useStateManager } from "./stateManager";
import Card from "components/card/Card";

export default function SubscriptionBox(props) {
  const { ...rest } = props;
  // Chakra Color Mode
  const { gptOutput } = useStateManager();
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  return (
    gptOutput && (
      <Card mb="20px" {...rest}>
        <Flex align="center" w="100%" justify="space-between" mb="30px">
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="xl"
            align="center"
            mx="auto"
          >
            Unlock Your Sustainable Style: Get Your Essential Wardrobe List Now!
          </Text>
        </Flex>
        <div id="custom-substack-embed">
          <iframe
            title="subscribe"
            src="https://fashionmeetsai.substack.com/embed"
            width="100%"
          ></iframe>
        </div>
      </Card>
    )
  );
}
