// Chakra imports
import { Heading, SimpleGrid, Text, useColorModeValue } from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";
import FabricInfo from "./FabricInfo.";
import { useStateManager } from "./stateManager";

// Assets
export default function Fabric(props) {
  const { input, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const cardShadow = useColorModeValue(
    "0px 18px 40px rgba(112, 144, 176, 0.12)",
    "unset"
  );
  const { gptOutput } = useStateManager();
  const brandColor = useColorModeValue("brand.500", "white");
  return (
    gptOutput && (
      <Card mb={{ base: "0px", "2xl": "20px" }} {...rest}>
        <Text
          color={textColorPrimary}
          fontWeight="bold"
          fontSize="2xl"
          mt="10px"
          mb="4px"
        >
          Fabric Breakdown
        </Text>
        <Text color={textColorSecondary} fontSize="md" me="26px" mb="40px">
          Explore a comprehensive breakdown of each fabric present in your item.
          Discover the benefits and drawbacks of your item's specific fabric
          blend. Enhance your understanding of the materials, optimizing your
          decision-making process for sustainable, high-quality choices in
          fashion.
        </Text>
        <SimpleGrid columns="1" gap="20px">
          {gptOutput["fabricAnalysis"]?.map((item, index) => (
            <FabricInfo
              boxShadow={cardShadow}
              title={item.naturalOrSynthetic}
              value={item.fabricType}
              info={
                <div className="fabric-div">
                  <div>
                    <Heading as="h4" size="sm" color={brandColor}>
                      Benefits
                    </Heading>
                    {item.benefits}
                  </div>
                  <div>
                    <Heading as="h4" size="sm" color={brandColor}>
                      Drawbacks
                    </Heading>
                    {item.drawbacks}
                  </div>
                  <div>
                    <Heading as="h4" size="sm" color={brandColor}>
                      Percentage Assesment
                    </Heading>
                    {item.percentageAssessment}
                  </div>
                  <div>
                    <Heading as="h4" size="sm" color={brandColor}>
                      Recommendation
                    </Heading>
                    <Text> {item.recommendation}</Text>
                  </div>
                </div>
              }
              key={index}
            />
          ))}
        </SimpleGrid>
      </Card>
    )
  );
}
