// Chakra imports
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Text,
  useColorModeValue,
} from "@chakra-ui/react";
// Custom components
import Card from "components/card/Card.js";
import React from "react";

export default function FabricInfo(props) {
  const { title, value, info, ...rest } = props;
  // Chakra Color Mode
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  const bg = useColorModeValue("white", "navy.700");

  return (
    <Accordion
      bg={bg}
      {...rest}
      allowMultiple
      allowToggle
      className="accordion-style"
    >
      <AccordionItem id="accordion-item">
        <Card>
          <AccordionButton id="accordion-button">
            <Box as="span" flex="1" textAlign="left">
              <Text fontWeight="500" color={textColorSecondary} fontSize="sm">
                {title}
              </Text>
              <Text color={textColorPrimary} fontWeight="500" fontSize="md">
                {value}
              </Text>
            </Box>
            <AccordionIcon />
          </AccordionButton>
        </Card>
        <AccordionPanel pb={4}>{info}</AccordionPanel>
      </AccordionItem>
    </Accordion>
  );
}
