// Chakra imports
import { Flex, Text, useColorModeValue } from "@chakra-ui/react";
import Card from "components/card/Card.js";
// Custom components
import { useStateManager } from "./stateManager";

export default function CareInstructions(props) {
  const { ...rest } = props;
  // Chakra Color Mode
  const { gptOutput } = useStateManager();
  const textColorPrimary = useColorModeValue("secondaryGray.900", "white");
  const textColorSecondary = "gray.400";
  return (
    gptOutput && (
      <Card mb="20px" {...rest}>
        <Flex align="center" w="100%" justify="space-between" mb="30px">
          <Text
            color={textColorPrimary}
            fontWeight="bold"
            fontSize="2xl"
            mb="4px"
          >
            Care Instructions
          </Text>
        </Flex>
        <Text color={textColorSecondary} fontSize="lg">
          {gptOutput.overallCareInstructions}
        </Text>
      </Card>
    )
  );
}
